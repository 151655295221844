import { Directive, HostListener } from '@angular/core'
import { Router } from '@angular/router'

import { AppConfigurationService } from '../core/services/app-configuration.service'

@Directive({
    selector: '[appGoHome]',
})
export class GoHomeDirective {
    constructor(private router: Router, private appConfigService: AppConfigurationService) {}

    @HostListener('click', ['$event']) onClick($event) {
        this.goHome()
    }

    goHome() {
        if (this.router.url.indexOf('manage-saleyard-auction') !== -1) {
            this.router.navigateByUrl('/')
        } else if (this.router.url.indexOf('manage-auction') !== -1) {
            window.location = this.appConfigService.getConfig().manageWebsiteUrl
        } else if (this.router.url.indexOf('auctions') !== -1) {
            window.location = this.appConfigService.getConfig().publicWebsiteUrl
        }
    }
}
