import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { tap, map, concatWith } from 'rxjs/operators'
import { Observable, forkJoin, of } from 'rxjs'
import { saveAs } from 'file-saver'

import { AuctionMedia, MetaType } from '../models/auction-media'
import { MediaType } from '../models/media-type'
import { AppConfigurationService } from '../../../../core/services/app-configuration.service'

@Injectable()
export class LotMediaApiService {
    constructor(private http: HttpClient, private appConfigService: AppConfigurationService) {}

    private _apiUrl: string
    private get apiUrl(): string {
        if (this._apiUrl == null) {
            const appConfig = this.appConfigService.getConfig()
            this._apiUrl = `${appConfig.auctionApiUrl}${appConfig.auctionApiVirtualDirectory}`
        }
        return this._apiUrl
    }

    addPhotos(auctionId: number | null, listingId: number, files, photos: AuctionMedia[], videos: AuctionMedia[]): Observable<AuctionMedia[]> {
        // Setup new photos
        const newPhotos = this.setupNewPhotos(files, photos, videos)

        const observables$: Observable<any>[] = []
        newPhotos.forEach(photo => {
            const post$ = this.postMedia(auctionId, listingId, photo.meta, photo.file)
            observables$.push(post$)
        })

        return forkJoin(observables$)
    }

    getPhotos(data: AuctionMedia[]): AuctionMedia[] {
        // Get photos
        const result = data.filter(i => i.mediaType === MediaType.Image && i.metaType === MetaType.None)

        // Setup URLs
        result.forEach(item => {
            this.setupUrl(item)
        })

        return result
    }

    getVideos(data: AuctionMedia[]): AuctionMedia[] {
        // Get videos
        const result = data.filter(i => i.mediaType === MediaType.Video)

        // Setup URLs
        result.forEach(item => {
            this.setupUrl(item)
        })

        return result
    }

    getDocuments(data: AuctionMedia[]): AuctionMedia[] {
        // Get docs
        const result = data.filter(i => i.mediaType === MediaType.Document && i.metaType === MetaType.None)

        // Setup URLs
        result.forEach(item => {
            this.setupUrl(item)
        })

        return result
    }

    getGeneticsDocuments(data: AuctionMedia[]): AuctionMedia[] {
        // Get docs
        const result = data.filter(i => i.mediaType === MediaType.Document && i.metaType === MetaType.Genetics)

        // Setup URLs
        result.forEach(item => {
            this.setupUrl(item)
        })

        return result
    }

    getUrls(data: AuctionMedia[]): AuctionMedia[] {
        // Get links
        const result = data.filter(i => i.mediaType === MediaType.Link)

        return result
    }

    getVendorLogos(data: AuctionMedia[]): AuctionMedia[] {
        // Get photos
        const result = data.filter(i => i.mediaType === MediaType.Image && i.metaType === MetaType.VendorLogo)

        // Setup URLs
        result.forEach(item => {
            this.setupUrl(item)
        })

        return result
    }

    deletePhoto(photo: AuctionMedia, photos: AuctionMedia[], videos: AuctionMedia[]): Observable<AuctionMedia[]> {
        // Remove photo
        const result = photos.filter(i => i.id !== photo.id)
        const observables$: Observable<any>[] = []

        // Check whether primary photo should be deleted
        if (photo.isPrimary && result.length > 0) {
            // Setup photo as a primary
            result[0].isPrimary = true
            // PUT API
            const updatePhoto$ = this.setupPrimary(result[0], photos, videos)
            observables$.push(updatePhoto$)
        }

        // Check whether primary photo should be deleted and there is no other photos but videos present
        if (photo.isPrimary && result.length === 0 && videos.length > 0) {
            // Setup a video as a primary
            videos[0].isPrimary = true
            // PUT API
            const updateVideo$ = this.setupPrimary(videos[0], photos, videos)
            observables$.push(updateVideo$)
        }

        // DELETE
        const deletePhoto$ = this.deleteLotMedia(photo.id)

        observables$.push(deletePhoto$)

        // Execute requests in a sequence
        return this.executeInASequence(observables$).pipe(
            map(r => {
                return result
            })
        )
    }

    setupPrimary(item: AuctionMedia, photos: AuctionMedia[], videos: AuctionMedia[]): Observable<any> {
        // Check whether photo is already primary
        if (item.isPrimary) {
            // Do not add anything
            new Observable(o => {
                o.next()
                o.complete()
            })
        }

        // Remove primary from photos
        const photos$ = this.removePrimary(photos)

        // Remove primary from videos
        const videos$ = this.removePrimary(videos)

        // API PUT
        const setup$ = this.putLotMedia(item.id, '?IsPrimary=true')

        const result$ = forkJoin(photos$, videos$, setup$).pipe(
            map(r => {
                // Setup primary state
                item.isPrimary = true
            })
        )

        return result$
    }

    addVideo(auctionId, listingId, files, videos, photos): Observable<AuctionMedia> {
        // Get video
        const video = files[0]

        // Setup details
        const uploadedFile: AuctionMedia = {
            filename: video.name,
            mediaType: MediaType.Video,
            metaType: MetaType.None,
        }

        // Setup primary flag
        uploadedFile.isPrimary = this.getPrimary(videos, photos)

        // POST
        const result$ = this.postMedia(auctionId, listingId, uploadedFile, video)

        // Return result
        return result$
    }

    deleteVideo(video, videos, photos) {
        // Remove photo
        const result = videos.filter(i => i.id !== video.id)
        const observables$: Observable<any>[] = []

        // Check whether primary video should be deleted
        if (video.isPrimary && result.length > 0) {
            // Setup primary flag to next item
            result[0].isPrimary = true
            // PUT API
            const updateVideo$ = this.setupPrimary(result[0], photos, videos)
            observables$.push(updateVideo$)
        }

        // Check whether primary video should be deleted and there is no other videos but photos present
        if (video.isPrimary && result.length === 0 && photos.length > 0) {
            // Setup a photo as a primary
            photos[0].isPrimary = true
            // TODO: PUT API
            const updatePhoto$ = this.setupPrimary(photos[0], photos, videos)
            observables$.push(updatePhoto$)
        }
        // DELETE
        const deleteVideo$ = this.deleteLotMedia(video.id)
        observables$.push(deleteVideo$)

        // Execute requests in a sequence
        return this.executeInASequence(observables$).pipe(
            map(r => {
                return result
            })
        )
    }

    addVendorLogo(auctionId, listingId, files, logos: AuctionMedia[]): Observable<AuctionMedia> {
        // Setup vendor logo
        const logo = files[0]

        // Setup details
        const uploadedFile: AuctionMedia = {
            filename: logo.name,
            mediaType: MediaType.Image,
            metaType: MetaType.VendorLogo,
        }

        const result$ = this.postMedia(auctionId, listingId, uploadedFile, logo)

        // Return result
        return result$
    }

    deleteVendorLogo(logo: AuctionMedia, logos: AuctionMedia[]): Observable<AuctionMedia[]> {
        const deleteLogo$ = this.deleteLotMedia(logo.id).pipe(
            map(r => {
                const result = logos.filter(i => i.id !== logo.id)
                return result
            })
        )

        return deleteLogo$
    }

    addFile(auctionId, listingId, newfiles, filename): Observable<AuctionMedia> {
        // Get file
        const file = newfiles[0]

        // Setup details
        const uploadedFile: AuctionMedia = {
            filename: filename,
            mediaType: MediaType.Document,
            metaType: MetaType.None,
            isPrimary: false,
        }

        // API POST
        return this.postMedia(auctionId, listingId, uploadedFile, file)
    }

    addGeneticsFile(auctionId, listingId, newfiles, filename): Observable<AuctionMedia> {
        // Get file
        const file = newfiles[0]

        // Setup details
        const uploadedFile: AuctionMedia = {
            filename: filename,
            mediaType: MediaType.Document,
            metaType: MetaType.Genetics,
            isPrimary: false,
        }

        // API POST
        return this.postMedia(auctionId, listingId, uploadedFile, file)
    }

    deleteLotFile(file, files): Observable<AuctionMedia[]> {
        // API DELETE
        return this.deleteLotMedia(file.id).pipe(
            map(r => {
                const result = files.filter(i => i.id !== file.id)
                return result
            })
        )
    }

    addLotUrl(lotId, item: AuctionMedia): Observable<AuctionMedia> {
        return this.postLotMediaLink(lotId, {
            name: item.filename,
            url: item.url,
        }).pipe(
            map(r => {
                item.id = r.id
                return item
            })
        )
    }

    editLotUrl(item: AuctionMedia, urls: AuctionMedia[]): Observable<any> {
        return this.putLotMediaLink(item.id, {
            name: item.filename,
            url: item.url,
        }).pipe(
            map((r: any) => {
                const link = urls.find(i => i.id === item.id)
                link!.filename = r.name
                link!.url = r.url
            })
        )
    }

    deleteLotUrl(url, urls: AuctionMedia[]): Observable<AuctionMedia[]> {
        return this.deleteLotMediaLink(url.id).pipe(
            map(r => {
                const result = urls.filter(i => i.id !== url.id)
                return result
            })
        )
    }

    downloadFile(media: AuctionMedia) {
        return this.getAsBlob(media.url).pipe(
            tap(response => {
                saveAs(response, media.filename)
            })
        )
    }

    private postMedia(auctionId, listingId, media: AuctionMedia, file: File): Observable<AuctionMedia> {
        // Setup form data
        const formData: FormData = new FormData()
        formData.append('formData', file, media.filename)
        // Encode filename
        const encodedFileName = encodeURIComponent(media.filename)
        // Setup query parameters
        const queryParams = `?FileName=${encodedFileName}&IsPrimary=${media.isPrimary}&MediaType=${media.mediaType}&MetaType=${media.metaType}`

        const post$ = this.postLotMedia(auctionId, listingId, queryParams, formData).pipe(
            map((r: AuctionMedia) => {
                media.id = r.id
                media.thumbnailUrl = r.thumbnailUrl
                media.url = r.url
                // Convert relative URL to Absolute
                this.setupUrl(media)
                return media
            })
        )

        return post$
    }

    private setupNewPhotos(files, photos, videos): { file: File; meta: AuctionMedia }[] {
        // Define result
        const result: any[] = []

        // Setup new photos
        for (let index = 0; index < files.length; index++) {
            const file = files[index]

            // Setup file details
            const uploadedFile: AuctionMedia = {
                filename: file.name,
                mediaType: MediaType.Image,
                isPrimary: false,
                metaType: MetaType.None,
            }

            // Add file details
            result.push({ meta: uploadedFile, file: file })
        }

        // Setup primary
        result[0].meta.isPrimary = this.getPrimary(videos, photos)

        // Return result
        return result
    }

    private removePrimary(items: AuctionMedia[]): Observable<any> {
        // Find existing primary item
        const primaryItem = items.find(i => i.isPrimary === true)
        // Check whether primary item found
        if (primaryItem != null) {
            // Remove old primary state
            primaryItem.isPrimary = false
            // API PUT
            return this.putLotMedia(primaryItem.id, '?IsPrimary=false')
        }

        return new Observable(o => {
            o.next()
            o.complete()
        })
    }

    private getPrimary(videos, photos): boolean {
        // Find existing primary video
        const primaryVideo = videos.find(i => i.isPrimary === true)

        // Find existing primary photo
        const primaryPhoto = photos.find(i => i.isPrimary === true)

        // Check whether primary video or photo already exists
        if (primaryVideo != null || primaryPhoto != null) {
            return false
        }

        return true
    }

    private executeInASequence(observables$: Observable<any>[]): Observable<any> {
        // Setup initial observable
        let result = of(1)

        // Add observables one after another
        observables$.forEach(item => {
            result = result.pipe(concatWith(item))
        })

        return result
    }

    protected postLotMedia(auctionId: number | null, listingId: number, query: string, data): Observable<any> {
        let fullUrl = this.lotsManageMedia(auctionId, listingId)
        fullUrl += query
        return this.http.post(fullUrl, data)
    }

    protected putLotMedia(mediaId: number | undefined, query): Observable<any> {
        let fullUrl = this.putLotsManageMedia(mediaId)
        fullUrl += query
        return this.http.put(fullUrl, null)
    }

    protected deleteLotMedia(mediaId: number | undefined): Observable<any> {
        const fullUrl = this.deleteLotsManageMedia(mediaId)
        return this.http.delete(fullUrl)
    }

    protected postLotMediaLink(listingId: number, data): Observable<any> {
        const fullUrl = this.postLotManageMediaLink(listingId)
        return this.http.post(fullUrl, data)
    }

    protected putLotMediaLink(mediaId: number | undefined, data): Observable<any> {
        const fullUrl = this.lotManageMediaLink(mediaId)
        return this.http.put(fullUrl, data)
    }

    protected deleteLotMediaLink(mediaId: number): Observable<any> {
        const fullUrl = this.lotManageMediaLink(mediaId)
        return this.http.delete(fullUrl)
    }

    protected getAsBlob(url: string | undefined) {
        return this.http.get(url!, { responseType: 'blob' })
    }

    private setupUrl(media: AuctionMedia) {
        // Setup full urls
        if (media.mediaType === MediaType.Link) {
            return
        }

        // media.url = this.globals.getApiUrl(media.url);
        // media.thumbnailUrl = this.globals.getApiUrl(media.thumbnailUrl);
        media.url = media.url
        media.thumbnailUrl = media.thumbnailUrl
    }

    private lotsManageMedia(auctionId: number | null, listingId: number): string {
        return `${this.apiUrl}/saleyard/auctions/${auctionId}/listings/${listingId}/media`
    }

    private putLotsManageMedia(id: number | undefined): string {
        return `${this.apiUrl}/saleyard/listingmedia/${id}/meta`
    }

    private deleteLotsManageMedia(id: number | undefined): string {
        return `${this.apiUrl}/saleyard/listingmedia/${id}`
    }

    private postLotManageMediaLink(listingId: number | undefined): string {
        return `${this.apiUrl}/saleyard/listingmedialink/${listingId}/links`
    }

    private lotManageMediaLink(id: number | undefined): string {
        return `${this.apiUrl}/saleyard/listingmedialink/${id}`
    }
}
