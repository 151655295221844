import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { take, map, mergeMap } from 'rxjs/operators'

import { UserService } from '../services/user.service'
import { OidcSecurityService } from 'angular-auth-oidc-client'
import { preventPermissionsGuard, userData } from 'app/core/services/app-loader.service'

/**
 * Checks the permissions of the user and whether it has access to the guarded route
 * */
@Injectable()
export class PermissionsGuard implements CanActivate {
    constructor(private userService: UserService, private oidcSecurityService: OidcSecurityService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (userData.value == '' || preventPermissionsGuard.value) {
            preventPermissionsGuard.next(false)
            return true
        }

        return this.oidcSecurityService.isAuthenticated$.pipe(
            take(1),
            mergeMap(() => this.userService.loadUserProfile()),
            map(userProfile => {
                if (userProfile != null) {
                    const userPermissions = userProfile.permissions!.map(p => p.key)
                    const requiredPermissions: string[] = route.data['permissions']
                    for (let i = 0; i < requiredPermissions.length; i++) {
                        const currentPermission = requiredPermissions[i]
                        if (userPermissions.indexOf(currentPermission) === -1) {
                            this.router.navigate(['forbidden'])
                            return false
                        }
                    }
                    return true
                }
                return true
            })
        )
    }
}
