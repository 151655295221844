import { Component, OnInit } from '@angular/core'
import { EventTypes, LoginResponse, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client'

import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { SvgIcon } from './shared/constants/icons.constants'
import { environment } from '../environments/environment'
import { UserService } from 'app/core/services/user.service'
import { mountRouter, preventPermissionsGuard, preventRoleGuard } from 'app/core/services/app-loader.service'
import { filter, take } from 'rxjs'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    moduleSetup: boolean
    hideLatency: boolean
    preventRouterActivation: boolean = false

    get mountRouter() {
        return mountRouter.value
    }

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private userService: UserService,
        private eventService: PublicEventsService
    ) {
        this.hideLatency = environment.production
        const value = this.userService.getCookie('fromApp')
        if (value === 'yes' || window.location.hash.includes('id_token')) {
            preventPermissionsGuard.next(true)
            preventRoleGuard.next(true)
            const cookie = this.userService.getCookieData()
            document.cookie = `fromApp=;path=/;domain=${cookie.domain};expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        }
    }

    ngOnInit(): void {
        registerIcons(this.matIconRegistry, this.domSanitizer)

        this.oidcSecurityService.checkAuth().subscribe((loginResponse: LoginResponse) => {
            if (loginResponse.isAuthenticated && loginResponse.userData) {
                this.userService.loadUserInfo(loginResponse).subscribe()
            } else {
                if (!loginResponse.errorMessage) {
                    this.preventRouterActivation = true
                    this.oidcSecurityService.logoffLocal()
                    this.oidcSecurityService.forceRefreshSession().subscribe(data => {
                        if (data && data.isAuthenticated) {
                            setTimeout(() => {
                                window.location.reload()
                            }, 500)
                        } else {
                            this.oidcSecurityService.authorize()
                        }
                    })
                }
            }
        })

        mountRouter.subscribe(data => {
            if (data) {
                this.moduleSetup = true
            }
        })

        this.eventService
            .registerForEvents()
            .pipe(filter(notification => notification.type === EventTypes.CheckSessionReceived))
            .subscribe(event => {
                if (event.value == 'changed') {
                    this.oidcSecurityService.logoffLocal()
                    this.oidcSecurityService.forceRefreshSession().subscribe(() => {
                        setTimeout(() => {
                            window.location.reload()
                        }, 500)
                    })
                }
            })

        this.eventService
            .registerForEvents()
            .pipe(
                filter(notification => notification.type === EventTypes.CheckSessionReceived),
                take(1)
            )
            .subscribe(event => {
                if (event.value == 'unchanged') {
                    setTimeout(() => {
                        if (!this.preventRouterActivation) {
                            mountRouter.next(true)
                        }
                    }, 500)
                }
            })

        setInterval(() => {
            this.oidcSecurityService.checkSessionChanged$.subscribe(hasChange => {
                if (hasChange) {
                    this.oidcSecurityService.forceRefreshSession().subscribe(data => {
                        if (!data) {
                            mountRouter.next(false)
                            this.oidcSecurityService.authorize()
                        }
                    })
                }
            })
        }, 5000)
    }
}

/**
 * Registers all svg icons on first load, so don't have to do it per component.
 * Add icons to the icons.constants.ts file and then to the iconArr array to make them
 * available.
 */
function registerIcons(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    const iconArr: { name: SvgIcon; file: string }[] = [
        { name: 'dollar', file: 'dollar.svg' },
        { name: 'timer', file: 'timer.svg' },
        { name: 'dollar-outlined', file: 'dollar-outlined.svg' },
        { name: 'home', file: 'home.svg' },
        { name: 'cow-goes-moo', file: 'cow-goes-moo.svg' },
        { name: 'going-going-gone', file: 'going-going-gone.svg' },
        { name: 'pick-black', file: 'pick-black.svg' },
        { name: 'pick-white', file: 'pick-white.svg' },
        { name: 'pick-grey', file: 'pick-grey.svg' },
        { name: 'runs-black', file: 'runs-black.svg' },
        { name: 'runs-white', file: 'runs-white.svg' },
        { name: 'runs-grey', file: 'runs-grey.svg' },
        { name: 'highest-bidder', file: 'highest-bidder.svg' },
        { name: 'under-bidder', file: 'under-bidder.svg' },
        { name: 'private-message', file: 'private-message.svg' },
        { name: 'external-link', file: 'external-link.svg' },
        { name: 'notifications-active', file: 'notifications-active.svg' },
        { name: 'notifications-off', file: 'notifications-off.svg' },
    ]

    iconArr.forEach(item => {
        matIconRegistry.addSvgIcon(item.name, domSanitizer.bypassSecurityTrustResourceUrl(`app/../assets/icons/${item.file}`))
    })
}
