import { Injectable } from '@angular/core'
import { Subscription, Subject, timer } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'
import { Router } from '@angular/router'

@Injectable()
export class SessionTimeoutService {
    private subscription: Subscription | null
    private subject = new Subject<void>()

    constructor(private router: Router) {}

    // default to 180 mins session
    resetTimeout(timeoutMs = 10800000) {
        if (this.subscription == null) {
            this.subscription = this.subject
                .pipe(
                    switchMap(() => {
                        return timer(timeoutMs)
                    }),
                    tap(() => {
                        this.router.navigate(['auto-logout'])
                    })
                )
                .subscribe()
        }
        this.subject.next()
    }

    stopTimeout() {
        if (this.subscription != null) {
            this.subscription.unsubscribe()
            this.subscription = null
        }
    }

    debugResetTimeout() {
        const newResetTimeout = +prompt('Hey you are not supposed to be here!!! Set session to timeout in (secs)', '1800')!
        if (newResetTimeout > 0) {
            this.stopTimeout()
            this.resetTimeout(newResetTimeout * 1000)
        }
    }
}
