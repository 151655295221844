<nav class="navbar navbar-default" style="background-color: #f8f8f8">
    <div class="container-fluid">
        <div class="navbar-header">
            <a class="navbar-brand nav-logo"></a>
        </div>

        <div class="navbar-text header-title" *ngIf="headerTitle$ | async as headerTitle">
            {{ headerTitle }}
        </div>
        <div class="navbar-text header-subtitle" style="margin-left: 50px" *ngIf="headerSubtitle$ | async as headerSubtitle">
            {{ headerSubtitle }}
        </div>

        <div *ngIf="isAuthorized && (userData$ | async) as userData" class="navbar-text" style="margin-right: 50px; margin-left: auto">
            Hello {{ userData.firstName + ' ' + userData.lastName }}
        </div>
        <ul class="nav navbar-nav">
            <li *ngIf="!isAuthorized">
                <a (click)="login()"><span class="fa fa-user"></span> Login</a>
            </li>
            <li>
                <a (click)="goBack()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16">
                        <path
                            fill="#808080"
                            d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z"
                        />
                    </svg>
                    Home</a
                >
            </li>
        </ul>
    </div>
</nav>
