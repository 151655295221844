import { Injectable } from '@angular/core'

import * as Messages from '../../../messages'

/**
 * Replaces %variableName% occurrence with it's value for all keys in data object.
 */
export function replaceKeys(str?: string, data?: Object): string | undefined {
    if (!str || !data || typeof data !== 'object') {
        return str
    }
    return Object.keys(data).reduce((acc, key) => {
        return acc.replace('%' + key + '%', data[key])
    }, str)
}

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    messages = Messages

    constructor() {}

    getMessage(keyPath: string, values?: Object) {
        const path = keyPath.split('.')
        const message = path.reduce((acc, key) => {
            if (acc) {
                return acc[key]
            }
            return undefined
        }, this.messages)

        if (values) {
            return replaceKeys(message, values)
        }
        return message
    }
}
