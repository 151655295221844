import { Injectable } from '@angular/core'

@Injectable()
export class SoundService {
    isSoundDisabled = false

    constructor() {
        this.isSoundDisabled = localStorage.getItem('isSoundDisabled') === 'true'
    }

    setSoundStatus(status: boolean): void{
        this.isSoundDisabled = status
    }

    playSound(soundFile: string) {
        if(this.isSoundDisabled) return

        const sound = new Audio()
        sound.src = `../../../assets/sounds/${soundFile}.mp3`

        let playPromise = sound.play()

        if (playPromise !== undefined) {
            playPromise.then().catch(() => {
                // Do nothing and hide the error from the console so it's not captured by Sentry
            })
        }
    }
}
