import { Component, OnInit } from '@angular/core'
import { OidcSecurityService } from 'angular-auth-oidc-client'

@Component({
    selector: 'app-auto-login',
    templateUrl: './auto-login.component.html',
    styleUrls: ['./auto-login.component.css'],
})
export class AutoLoginComponent implements OnInit {
    constructor(private oidcSecurityService: OidcSecurityService) {}

    ngOnInit() {
        localStorage.setItem('fromLogin', 'true')
        this.oidcSecurityService.authorize()
    }
}
