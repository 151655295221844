import { NgModule } from '@angular/core'

import { LatencyDisplayComponent } from './latency-display/latency-display.component'
import { SharedModule } from '../shared/shared.module'
import { LatencyCalculationService } from './services/latency-calculation.service'

@NgModule({
    imports: [SharedModule],
    providers: [LatencyCalculationService],
    exports: [LatencyDisplayComponent],
    declarations: [LatencyDisplayComponent],
})
export class LatencyToolsModule {}
