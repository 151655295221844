import { Component, OnInit } from '@angular/core'
import { MessageService } from '../services/message.service'
import { LocalStorageService } from 'app/core/services/local-storage.service'
import { SoundService } from 'app/core/services/sound.service'
import { LayoutService } from 'app/core/services/layout.service'
import { LayoutHeaderEnum } from 'app/core/constants/layout-header.enum'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    isSoundDisabled = false
    auctionId: number = 0
    auctionsMuted: number[] = []

    constructor(
        readonly messageService: MessageService,
        readonly localStorageService: LocalStorageService,
        readonly soundService: SoundService,
        readonly layoutService: LayoutService
    ) {}

    ngOnInit() {
        this.localStorageService.getStorageChanges().subscribe((event: StorageEvent) => {
            this.checkIsSoundDisabled()
        })

        this.layoutService.header$.subscribe(header => {
            if (header[LayoutHeaderEnum.AuctionId] && parseInt(header[LayoutHeaderEnum.AuctionId]) > 0) {
                this.auctionId = parseInt(header[LayoutHeaderEnum.AuctionId])
                this.checkIsSoundDisabled()
            }
        })
    }

    checkIsSoundDisabled() {
        this.auctionsMuted = JSON.parse(localStorage.getItem('auctionsMuted') || '[]')
        const exists = this.auctionsMuted.find(auction => auction.toString() === this.auctionId.toString())
        this.isSoundDisabled = !!exists
        this.soundService.setSoundStatus(this.isSoundDisabled)
    }

    changeSoundStatus() {
        this.isSoundDisabled = !this.isSoundDisabled

        if (!this.auctionsMuted.includes(this.auctionId) && this.isSoundDisabled) {
            this.auctionsMuted.push(this.auctionId)
        } else {
            this.auctionsMuted = this.auctionsMuted.filter(auction => auction !== this.auctionId)
        }

        this.soundService.setSoundStatus(this.isSoundDisabled)
        localStorage.setItem('auctionsMuted', JSON.stringify(this.auctionsMuted))
    }
}
