import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'
import { LayoutHeaderEnum } from 'app/core/constants/layout-header.enum'
@Injectable()
export class LayoutService {
    public header$: BehaviorSubject<{[key:string]: string}> = new BehaviorSubject<{[key:string]: string}>({});

    constructor() {}

    updateHeader(param:LayoutHeaderEnum, value:string): void {
        const headerValue:{[key:string]: string} = {...this.header$.value}
        headerValue[param] = value;
        this.header$.next(headerValue);
    }
}
