import { LotMediaAdded } from '../../../modules/manage/saleyard/models/lot-media-added'
import { BidPresetGroupDirection, BidPresetGroupType } from '../../../modules/manage/shared/models'
import { LotActivityStatus } from '../../constants'
import { LotOfferStatus } from '../../constants/lot-offer-status.enum'
import { LotType } from '../../constants/lot-type'
import { Media, MediaMeta } from '../../../modules/public/models/media.model'
import { AnnouncementType } from '../../../modules/manage/shared/models/auction-response.model'

export interface HubClientPacket {
    Header: {
        Cmd: PayloadCommand
        AuctionId: number | null
        SentTimeUtc: Date
        UserProfileId: string | null
        MessageId: string | null
    }
    Payload:
        | AuctionCommencementPayload
        | AuctionConfigurationPayload
        | AuctionStatusUpdatePayload
        | IsEngineAlivePayload
        | LotAutoBidConfigurationPayload
        | LotBidHistoryPayload
        | LotBidMultipleHistoryPayload
        | LotBidPayload
        | LotCommencementPayload
        | LotCreatedPayload
        | LotInformationPayload
        | LotNearReservePayload
        | LotOrderUpdatePayload
        | LotPricingPayload
        | LotStatusUpdatePayload
        | LotUpdatedPayload
        | MessageNotificationPayload
        | SaleyardLotCreatedPayload
        | SaleyardLotUpdatedPayload
        | BidPresetCreatedPayload
        | BidPresetRemovedPayload
        | BidPresetGroupCreatedPayload
        | BidPresetGroupRemovedPayload
        | LotMediaAddedPayload
        | LotMediaRemovedPayload
        | null
}

export enum PayloadCommand {
    IsEngineAlive = 0xaa,
    AuctionCommencement = 0x01,
    AuctionFinish = 0x04,
    AuctionStatusUpdate = 0x02,
    AuctionConfiguration = 0x03,
    LotCommencement = 0x64,
    LotBid = 0x66,
    LotStatusUpdate = 0x67,
    BulkLotStatusUpdate = 0x90,
    Notification = 0x68,
    LotBidHistory = 0x69,
    LotNearReserve = 0x70,
    LotPricing = 0x71,
    LotInformation = 0x72,
    LotInformationMarketOperator = 0x73,
    LotAutoBidConfiguration = 0x75,
    LotBidMultipleHistory = 0x76,
    LotSalePriceChanged = 0x88,
    LotPostAuctionSaleCreated = 0x89,
    LotClosing = 0x78,
    LotNowSelling = 0x83,

    WithdrawBid = 0x7b,

    // Saleyard auction
    LotCreated = 0x7c,
    LotUpdated = 0x7d,
    LotOrderUpdate = 0x7e,
    LotMediaAdded = 0x9a,
    LotMediaRemoved = 0x9b,
    LotIncrement = 0x6a,
    LotStartPrice = 0x6b,
    LotDetail = 0x6c,
    AuctionCurrentLotStart = 0x79,
    AuctionCurrentLotClose = 0x7a,

    //Bid Presets
    BidPresetGroupCreated = 0x86,
    BidPresetGroupRemoved = 0x87,
    BidPresetCreated = 0x80,
    BidPresetRemoved = 0x81,

    //Offers
    UpdateOfferAcceptanceStatus = 0x8c,
    OfferUpdate = 0x8e,
}

export enum MessageNotificationCodes {
    Information = 0x0,
    SysError = 0x01,
}

export enum AuctionAction {
    Start = 1,
    Pause = 2,
    Ready = 3,
    Finished = 4,
}

export enum LotStatus {
    Started = 0,
    NowSelling = 1,
    Sold = 2,
    NotSold = 3,
    PassedIn = 4,
    WithdrawnNotSold = 5,
    FinishDelayed = 6,
    OfferManagement = 7,
    UnderOffer = 8,
    Removed = 9,
}

/**
 *  AuctionCommencementPayload
 * */

export interface AuctionCommencementPayload {
    Action: AuctionAction
}

/**
 *  AuctionStatusUpdatePayload
 * */
export interface AuctionStatusUpdatePayload {
    ActionIn: number
    Status: AuctionAction
}

/**
 *  LotCommencementPayload
 * */
export interface LotCommencementPayload {
    LotId: number
    StartPrice?: number | null
    StartingIn?: number
    TimeRemaining?: number
    Status: LotStatus
    WinningBidderId?: number | null
    WinningBidderNumber?: number | null
    ReserveStatus?: number
    LotClosing: boolean
    BiddingRun: number
    InitialPriceType?: number | null
    Increment?: number
}

/**
 *  LotBidPayload
 * */
export interface LotBidPayload {
    LotId: number
    Amount: number
    BidderId: number
}

/**
 *  LotStatusUpdate
 * */
export interface LotStatusUpdatePayload {
    LotId: number
    CurrentPrice?: number | null
    HasLot: boolean
    TimeRemaining?: number
    Status: LotStatus
    TimeExtended?: boolean
    WinningBidderId?: number | null
    WinningBidderNumber?: number | null
    Increment?: number | null
    TotalPrice?: number | null
    PricePerUnit?: number | null
    PricePerHeadEquivalent?: number | null
    ReserveStatus: number
    AllBidderIds: number[]
    LotClosing?: boolean
    PurchaserName?: string | null
    PurchaserAccount?: string | null
    PurchaserAccountName?: string | null
    PurchaserLocation?: string | null
    Tally?: number | null
    AveWeight?: number | null
    NowSelling?: boolean
    InitialPriceType?: number | null
    RunoutMinimumTally?: number | null
}

export interface BulkLotStatusUpdatePayload {
    LotStatusUpdates: LotStatusUpdatePayload[]
}

/**
 * MessageNotification
 * */

export interface MessageNotificationPayload {
    Code: MessageNotificationCodes
    Message: string
    Type: AnnouncementType
    RecipientAuctionBidderId?: number
    Title?: string
}

/**
 *  LotBidHistory
 * */
export interface LotBidHistoryPayload {
    BidId: number
    LotId: number
    Amount: number
    BidderId: number
    BidderNumber: number
    RecordedAt: Date
    ExtensionsCount: number
    IsAutoBid: boolean
    BidderName: string
    Withdrawn: boolean
    BiddingRun: number
    BidderAccountNumber: string
}

export interface LotBidHistoryWithInfoPayload {
    bids: {
        id: number
        createdAt: Date | null
        bidderInfo: {
            id: number
            bidderNumber: number
            bidderName: string
            bidderAccountNumber?: string
            bidderAccountName?: string
            bidderLocation?: string
        }
        amount: number
        lotId: number
        isAutoBid?: boolean
        withdrawn: boolean
        biddingRun: number
    }[]
}

/**
 * LotNearReserve
 * */
export interface LotNearReservePayload {
    LotId: number
    IsNearReserve: boolean
}

/**
 * LotPricing
 * */
export interface LotPricingPayload {
    LotId: number
    Increment: number
    IncrementAction: number
    ExpireAndExtendIncrement: number
    Tally: number
    AveWeight: number
    StartPrice: number
    ReservePrice: number
    InitialPriceType?: number
    PendingIncrement?: number
    AlternatingBidPresetGroupId?: number
    AlternatingBidIndex?: number
}

/**
 * AuctionConfiguration
 * */
export interface AuctionConfigurationPayload {
    CurrentTimings: {
        Duration: number
        LotFinishDelayInSec: number
        LotStartDelayInSec: number
        LotStartResumeDelayInSec: number
        TimeRemainingBeforeExtendInSec: number
        TimeToExtendInSec: number
    }
    PendingTimings: {
        Duration: number
        LotFinishDelayInSec: number
        LotStartDelayInSec: number
        LotStartResumeDelayInSec: number
        TimeRemainingBeforeExtendInSec: number
        TimeToExtendInSec: number
    }
}

/**
 * LotInformationPayload
 * for both LotInformationPayload and LotInformation
 * */
export interface LotInformationPayload {
    Lots: {
        LotId: number
        Increment: number
        StartPrice: number
        ReservePrice?: number
        Tally?: number
        AveWeight?: number
        ActivityStatus: number
        NoReserve: boolean
    }[]
}

/**
 * IsEngineAlivePayload
 * */
export interface IsEngineAlivePayload {}

/**
 * LotIncrement
 * */
export interface LotAutoBidConfigurationPayload {
    LotId: number
    Amount?: number
    BidderId: number
}

/**
 * LotBidMultipleHistory
 * */
export interface LotBidMultipleHistoryPayload {
    LotId: number
    ExtensionsCount: number
    Bids: {
        BidId: number
        Amount: number
        BidderId: number
        BidderNumber: number
        RecordedAt: Date
        IsAutoBid: boolean
        BidderName: string
        Withdrawn: boolean
    }[]
}

export interface LotOrderUpdatePayload {
    LotOrderDictionary: Record<number, number>
}

interface FullLotDetails {
    Id: number
    ListingId: number
    LotNumber: string
    Title: string
    AlternativeTitle: string
    Order?: number
    ReservePrice?: number
    NoReserve?: boolean
    Location: string
    AveWeight?: number
    //ToDo: Check if this is the correct Enum to be using
    Status: string
    //ToDo: Check if this is the correct Enum to be using
    ActivityStatus: LotActivityStatus
    StartPrice?: number
    TotalPrice?: number
    PricePerKg?: number
    PricePerHeadEquivalent?: number
    Increment?: number
    IncrementUnits: string
    IncrementDescription: string
    StockType: string
    Agent: string
    Agency: string
    Withholding?: boolean
    Tally?: number
    LotType?: LotType
    ChildIds?: number[]
    ChildMedia?: LotMediaAddedPayload[]
    ParentId?: number
    PickNotes?: string
    RunoutMinimumTally?: number
}

interface FullSaleyardLotDetails extends FullLotDetails {
    TbStatus: string
    Breed: string
    Age: string
    Description: string
    VendorName: string
    PendingIncrement?: number
    AlternatingBidPresetGroupId?: number
    AlternatingBidIndex?: number
}

/// <summary>
/// Payload containing new lot information
/// </summary>
export interface LotCreatedPayload extends FullLotDetails {}

export interface SaleyardLotCreatedPayload extends FullSaleyardLotDetails {}

export interface LotUpdatedPayload extends FullLotDetails {}

export interface SaleyardLotUpdatedPayload extends FullSaleyardLotDetails {}

export interface SaleyardLotMediaAddedPayload {
    LotId: number
    MediaMeta: LotMediaAdded
}

export interface SaleyardLotMediaRemovedPayload {
    MediaId: number
}
export interface BidPresetGroupCreatedPayload {
    Id: number
    AuctionId: number
    GroupType: BidPresetGroupType
    GroupDirection: BidPresetGroupDirection
    BidPresets: PresetBid[]
}

export interface PresetBid {
    Id: number
    Amount: number
    GroupId: number
}

export interface BidPresetGroupRemovedPayload {
    Id: number
}

export interface BidPresetCreatedPayload extends PresetBid {
    AuctionId: number
}

export interface BidPresetRemovedPayload {
    Id: number
    GroupId: number
}

export interface LotSalePriceChangePayload {
    LotId: number
    Amount: number
}

export interface LotPostAuctionSaleCreatedPayload {
    ActivityStatus: LotActivityStatus
    Bidder: string
    BidderId: number
    LotId: number
    PurchaserAccount: string
    PurchaserName: string
    SoldPrice: number
}

export interface OfferUpdatePayload {
    LotId: number
    OfferId: number
    Status: LotOfferStatus
    AcceptedLotIds?: number[]
    AcceptedTally?: number
    AvailableTally?: number
    BiddingRun: number
    BidderId: number
    Amount: number
}

export interface LotMediaAddedPayload {
    LotId: number
    Media: Media
    MediaMeta: MediaMeta
}

export interface LotMediaRemovedPayload {
    LotId: number
    MediaId: number
    MediaType: number
}
