<div class="nav-toolbar p-5 bg-white">
    <app-logo></app-logo>

    <button class="home-button" mat-stroked-button color="primary" appGoHome matTooltip="Home" [matTooltipPosition]="'right'">
        <span class="sr-only"> Home Page </span>
        <mat-icon svgIcon="home"></mat-icon>
    </button>

    <button class="bell-button" mat-stroked-button color="primary" (click)="changeSoundStatus()" matTooltip="Bidding Bell control" [matTooltipPosition]="'right'">
        <mat-icon [svgIcon]="isSoundDisabled ? 'notifications-off' : 'notifications-active'"></mat-icon>
    </button>
</div>
