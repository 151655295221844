import { Injectable } from '@angular/core'
import { HubConnectionBuilder, LogLevel, HubConnection, HttpTransportType } from '@microsoft/signalr'
import { from, Observable, Subject, of, EMPTY } from 'rxjs'
import { mergeMap } from 'rxjs/operators'

import { AppConfigurationService } from '../app-configuration.service'
import { environment } from '../../../../environments/environment'
import { HubClientPacket, PayloadCommand, LotCommencementPayload, LotStatusUpdatePayload } from '../../models/hub-client'
import { OidcSecurityService } from 'angular-auth-oidc-client'

/**
 *Service for managing the connection between the application
 * and the SignalR Hub on the API
 * */
@Injectable()
export class HubClientService {
    private connection: HubConnection | null

    private receiveAsyncSubject = new Subject<HubClientPacket>()
    receiveAsync$ = this.receiveAsyncSubject.asObservable()

    private onCloseConnectioSubject = new Subject<Error>()
    onCloseConnection$ = this.onCloseConnectioSubject.asObservable()

    private onReconnectingSubject = new Subject<any>()
    onReconnecting$ = this.onReconnectingSubject.asObservable()

    private onReconnectedSubject = new Subject<any>()
    onReconnected$ = this.onReconnectedSubject.asObservable()

    private latencyAdjustment = 0

    constructor(private appConfigService: AppConfigurationService, private oidcSecurityService: OidcSecurityService) {}

    /**
     * Builds the connection to the client hub and starts the connection
     * */
    connectToHub(): Observable<void> {
        const auctionEngineClientHubUrl = `${this.appConfigService.getConfig().auctionApiUrl}${this.appConfigService.getConfig().auctionApiVirtualDirectory}${
            this.appConfigService.getConfig().auctionApiClientHubUrl
        }`

        let accessToken: string = ''
        this.oidcSecurityService.getAccessToken().subscribe(token => {
            accessToken = token
        })

        this.connection = new HubConnectionBuilder()
            .configureLogging(environment.production ? LogLevel.Information : LogLevel.None)
            .withUrl(auctionEngineClientHubUrl, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,

                accessTokenFactory: () => {
                    return accessToken ? accessToken : ''
                },
            })
            .build()

        // Add Receive Handler
        this.connection.on('ReceiveAsync', this.receiveAsyncHandler.bind(this))

        return from(this.connection.start())
    }

    connectToHubWithRetry() {
        const buildConnection$ = of(() => {
            const auctionEngineClientHubUrl = `${this.appConfigService.getConfig().auctionApiUrl}${this.appConfigService.getConfig().auctionApiVirtualDirectory}${
                this.appConfigService.getConfig().auctionApiClientHubUrl
            }`

            let accessToken: string = ''
            this.oidcSecurityService.getAccessToken().subscribe(token => {
                accessToken = token
            })

            this.connection = new HubConnectionBuilder()
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: retryContext => {
                        if (retryContext.elapsedMilliseconds < 3000 && retryContext.previousRetryCount < 3) {
                            return 0
                        } else if (retryContext.previousRetryCount <= 500) {
                            return 3000
                        }

                        return null
                    },
                })
                .configureLogging(environment.production ? LogLevel.Information : LogLevel.None)
                .withUrl(auctionEngineClientHubUrl, {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,

                    accessTokenFactory: () => {
                        return accessToken ? accessToken : ''
                    },
                })
                .build()

            this.connection.serverTimeoutInMilliseconds = 30000

            // Add Receive Handler
            this.connection.on('ReceiveAsync', this.receiveAsyncHandler.bind(this))
            // Add OnClose Handler
            //this.connection.onclose(this.onCloseConnectionHandler.bind(this))
            this.connection.onclose(data => {
                if (!location.pathname.includes('manage-saleyard-auction')) this.onCloseConnectionHandler.bind(this)
            })

            // Add onReconnecting Handler
            this.connection.onreconnecting(this.onReconnectingHandler.bind(this))
            // Add onReconnected Handler
            this.connection.onreconnected(this.onReconnectedHandler.bind(this))

            return from(this.connection.start())
        }).pipe(mergeMap(buildConnection => buildConnection()))

        return buildConnection$
    }

    /**
     * Stops the connection from the client hub
     * */
    disconnectFromHub(): Observable<void> {
        if (this.connection != null) {
            const promise = this.connection.stop()
            this.connection = null
            return from(promise)
        } else {
            return from(Promise.resolve())
        }
    }

    /**
     * Invoke a method on the SignalR Hub
     * @param methodName the name of the method to invoke
     * @param args any arguments the method may need
     */
    callHubMethod<T = any>(methodName: string, ...args: any[]): Observable<T> {
        if (this.connection != null && this.connection!.state == 'Connected') {
            return from(this.connection.invoke<T>(methodName, ...args))
        }
        return EMPTY
    }

    private receiveAsyncHandler(dataString: string) {
        const packet: HubClientPacket = JSON.parse(dataString)
        // Add latency delay to TimeRemaining in packets
        if (packet.Header.Cmd === PayloadCommand.LotCommencement) {
            const payload = <LotCommencementPayload>packet.Payload
            // take off latency
            payload.TimeRemaining = this.getAdjustedTime(payload.TimeRemaining)
            payload.StartingIn = this.getAdjustedTime(payload.StartingIn)
        } else if (packet.Header.Cmd === PayloadCommand.LotStatusUpdate) {
            const payload = <LotStatusUpdatePayload>packet.Payload
            // take off latency
            payload.TimeRemaining = this.getAdjustedTime(payload.TimeRemaining)
        }
        this.receiveAsyncSubject.next(packet)
    }

    updateLatencyAdjustment(adjustment: number) {
        this.latencyAdjustment = adjustment
    }

    private getAdjustedTime(time?: number): number | undefined {
        if (time) {
            const adjustedTime = time - this.latencyAdjustment
            return adjustedTime >= 0 ? adjustedTime : 0
        }
        return time
    }

    private onCloseConnectionHandler(error?: any) {
        this.onCloseConnectioSubject.next(error || null)
    }

    private onReconnectingHandler(data?: any) {
        this.onReconnectingSubject.next(data || null)
    }

    private onReconnectedHandler(data?: any) {
        this.onReconnectedSubject.next(data || null)
    }
}
