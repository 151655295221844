import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { OidcSecurityService } from 'angular-auth-oidc-client'
import { Observable } from 'rxjs'
import { take, map } from 'rxjs/operators'

/**
 *  Redirects the user to the identity server login when trying to navigate to the guarded guard if not logged in yet
 * */
@Injectable()
export class AutoLoginGuard implements CanActivate {
    constructor(private oidcSecurityservice: OidcSecurityService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.oidcSecurityservice.isAuthenticated$.pipe(
            take(1),
            map((data: any) => {
                if (!data.isAuthenticated) {
                    localStorage.setItem('goingTo', state.url.toString())
                }

                return true
            })
        )
    }
}
