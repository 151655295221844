<div class="errorPage">
    <mat-icon class="xl-size" color="error">warning</mat-icon>
    <h2 class="mt-0 mb-2 pb-0 text-danger">{{ title }}</h2>

    <div class="md-font-size max-w-50 mx-auto">
        {{ message }}
    </div>
    <small class="text-muted">{{ errorDateTime | date: 'medium' }}</small
    ><br />
    <small class="text-muted">{{ getReadableRedirectUrl() }}</small
    ><br />
    <button mat-raised-button color="primary" class="mt-4 lg-size home-button" type="button" (click)="onContinueClicked()">
        {{ getButtonText() }}
    </button>
    <br />
    <small
        >or
        <br />
        <a class="text-primary" href="/">Return to home</a></small
    >
</div>
