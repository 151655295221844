import { Component } from '@angular/core'
import { MessageService } from '../../../core/services/message.service'

@Component({
    selector: 'app-no-auction',
    templateUrl: './no-auction.component.html',
    styleUrls: ['./no-auction.component.scss'],
})
export class NoAuctionComponent {
    constructor(readonly messageService: MessageService) {}
}
