import { Component, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
    selector: 'app-bt-layout',
    templateUrl: './bt-layout.component.html',
    styleUrls: ['./bt-layout.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class BtLayoutComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
