export class AuctionMedia {
    id?: number
    filename: string
    url?: string
    mediaType: number
    metaType?: number
    thumbnailUrl?: string
    isPrimary?: boolean
    isLogo?: boolean
}

export enum MetaType {
    None = 0,
    AgencyLogo,
    VendorLogo,
    TermsAndConditions,
    Genetics,
}
