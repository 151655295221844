import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { tap, map } from 'rxjs/operators'
import { saveAs } from 'file-saver'
import { Observable } from 'rxjs'
import { AuctionMedia, MetaType } from '../models/auction-media'
import { MediaType } from '../models/media-type'
import { AppConfigurationService } from '../../../../core/services/app-configuration.service'

@Injectable()
export class AuctionMediaApiService {
    constructor(private http: HttpClient, private appConfigService: AppConfigurationService) {}

    private _apiUrl: string
    private get apiUrl(): string {
        if (this._apiUrl == null) {
            const appConfig = this.appConfigService.getConfig()
            this._apiUrl = `${appConfig.auctionApiUrl}${appConfig.auctionApiVirtualDirectory}`
        }
        return this._apiUrl
    }

    getUrls(data: AuctionMedia[]): AuctionMedia[] {
        // Get links
        const result = data.filter(i => i.mediaType === MediaType.Link)

        return result
    }

    getDocuments(data: AuctionMedia[]): AuctionMedia[] {
        // Get docs
        const result = data.filter(i => i.mediaType === MediaType.Document && i.metaType === MetaType.None)

        // Setup URLs
        result.forEach(item => {
            this.setupUrl(item)
        })

        return result
    }

    getAgencyLogos(data: AuctionMedia[]): AuctionMedia[] {
        // Get photos
        const result = data.filter(i => i.mediaType === MediaType.Image && i.metaType === MetaType.AgencyLogo)

        // Setup URLs
        result.forEach(item => {
            this.setupUrl(item)
        })

        return result
    }

    getVendorLogos(data: AuctionMedia[]): AuctionMedia[] {
        // Get photos
        const result = data.filter(i => i.mediaType === MediaType.Image && i.metaType === MetaType.VendorLogo)

        // Setup URLs
        result.forEach(item => {
            this.setupUrl(item)
        })

        return result
    }

    addFile(auctionId: number | null, file: File, media: AuctionMedia): Observable<any> {
        // Setup form data
        const formData: FormData = new FormData()
        formData.append('uploadFile', file, media.filename)

        // Encode filename
        const encodedFileName = encodeURIComponent(media.filename)

        // Setup query parameters
        const queryParams = `?FileName=${encodedFileName}&IsPrimary=${false}&MediaType=${media.mediaType}&MetaType=${media.metaType}`

        // Post to API
        return this.postFile(auctionId, queryParams, formData).pipe(
            map((r: AuctionMedia) => {
                // Setup full url to file
                this.setupUrl(r)

                // Return updated result
                return r
            })
        )
    }

    removeFile(id: number | undefined): Observable<any> {
        return this.deleteFile(id!)
    }

    addUrl(id: number, data): Observable<any> {
        return this.postUrl(id, data)
    }

    removeUrl(id: number | undefined): Observable<any> {
        return this.deleteUrl(id)
    }

    updateUrl(id: number, data): Observable<any> {
        return this.putUrl(id, data)
    }

    downloadFile(media: AuctionMedia) {
        return this.getAsBlob(media.url!).pipe(
            tap(response => {
                saveAs(response, media.filename)
            })
        )
    }

    protected postFile(auctionId: number | null, query: string, data) {
        const url = this.auctionMedia(auctionId)
        const urlWithParams = url + query
        return this.http.post(urlWithParams, data)
    }

    protected deleteFile(fileId: number) {
        const fullUrl = this.specificAuctionMedia(fileId)
        return this.http.delete(fullUrl)
    }

    protected postUrl(auctionId: number | null, data) {
        const fullUrl = this.auctionMediaLink(auctionId)
        return this.http.post(fullUrl, data)
    }

    protected deleteUrl(urlId: number | undefined) {
        const fullUrl = this.specificAuctionMediaLink(urlId)
        return this.http.delete(fullUrl)
    }

    protected putUrl(urlId: number, data) {
        const fullUrl = this.specificAuctionMediaLink(urlId)
        return this.http.put(fullUrl, data)
    }

    protected getAsBlob(url: string) {
        return this.http.get(url, { responseType: 'blob' })
    }

    private setupUrl(media: AuctionMedia) {
        // Setup full urls
        if (media.mediaType === MediaType.Link) {
            return
        }

        media.url = media.url
        media.thumbnailUrl = media.thumbnailUrl
    }

    private auctionMedia(auctionId: number | null): string {
        return `${this.apiUrl}/saleyard/auctions/${auctionId}/media`
    }

    private auctionMediaLink(auctionId: number | null): string {
        return `${this.apiUrl}/saleyard/auctions/${auctionId}/auctionmedialink`
    }

    private specificAuctionMedia(id: number): string {
        return `${this.apiUrl}/saleyard/auctionmedia/${id}`
    }

    private specificAuctionMediaLink(id: number | undefined): string {
        return `${this.apiUrl}/saleyard/auctionmedialink/${id}`
    }
}
