import { Component, OnInit, OnDestroy } from '@angular/core'
import { AuthenticatedResult, OidcSecurityService } from 'angular-auth-oidc-client'
import { Observable, Subscription } from 'rxjs'

import { HeaderService } from '../services/header.service'
import { UserService } from '../services/user.service'
import { Router } from '@angular/router'
import { AppConfigurationService } from '../services/app-configuration.service'

@Component({
    selector: 'app-bt-header',
    templateUrl: './bt-header.component.html',
    styleUrls: ['./bt-header.component.css'],
})
export class BtHeaderComponent implements OnInit, OnDestroy {
    headerTitle: string
    headerSubtitle: string

    isAuthorized: boolean | AuthenticatedResult
    userData$: Observable<any>
    headerTitle$: Observable<string>
    headerSubtitle$: Observable<string>

    subscription: Subscription

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private headerService: HeaderService,
        private userService: UserService,
        private router: Router,
        private appConfigService: AppConfigurationService
    ) {}

    ngOnInit() {
        this.subscription = this.oidcSecurityService.isAuthenticated$.subscribe(isAuthorized => (this.isAuthorized = isAuthorized))
        this.userData$ = this.userService.currentUser$
        this.headerTitle$ = this.headerService.headerTitle$
        this.headerSubtitle$ = this.headerService.headerSubtitle$
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }

    login() {
        localStorage.setItem('fromLogin', 'true')
        this.oidcSecurityService.authorize()
    }

    logout() {
        this.userService.logOut()
    }

    goBack() {
        if (this.router.url.indexOf('manage-auction') !== -1) {
            window.location = this.appConfigService.getConfig().manageWebsiteUrl
        } else if (this.router.url.indexOf('auctions') !== -1) {
            window.location = this.appConfigService.getConfig().publicWebsiteUrl
        } else {
            window.location = <any>'/'
        }
    }
}
