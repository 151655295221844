import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { take, map, mergeMap } from 'rxjs/operators'

import { UserService } from '../services/user.service'
import { OidcSecurityService } from 'angular-auth-oidc-client'
import { preventRoleGuard, userData } from 'app/core/services/app-loader.service'

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(private userService: UserService, private oidcSecurityService: OidcSecurityService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (userData.value == '' || preventRoleGuard.value) {
            preventRoleGuard.next(false)
            return true
        }

        return this.oidcSecurityService.isAuthenticated$.pipe(
            // filter(isAuthorized => isAuthorized),
            take(1),
            mergeMap(isAuthorized => this.userService.loadUserProfile()),
            map(userProfile => {
                if (userProfile != null) {
                    const userRole = userProfile.role!.key
                    const requiredRoles = route.data['roles']
                    if (Array.isArray(requiredRoles) && requiredRoles.includes(userRole)) {
                        return true
                    } else {
                        this.router.navigate(['forbidden'])
                        return false
                    }
                } else {
                    // TODO: user profile still null even after loading it, do something
                }
                return true
            })
        )
    }
}
