import { Component, OnInit, Input } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { MatIconRegistry } from '@angular/material/icon'

@Component({
    selector: 'app-external-link',
    templateUrl: './external-link.component.html',
    styleUrls: ['./external-link.component.scss'],
})
export class ExternalLinkComponent implements OnInit {
    @Input() href: string

    constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.iconRegistry.addSvgIcon('external-link', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/external-link.svg'))
    }
}
