import { Component, OnInit } from '@angular/core'
import { OidcSecurityService } from 'angular-auth-oidc-client'

@Component({
    template: `<div></div>`,
})
export class PostLogoutComponent implements OnInit {
    publicWebsiteUrl: string

    constructor(private oidcSecurityService: OidcSecurityService) {}

    ngOnInit() {
        this.oidcSecurityService.authorize()
    }
}
