export enum RoleTypes {
    // taken from SupportedRoles.cs

    ProviderAdmin = 'ProviderAdmin', //Super administrator account that has access to everything
    SysAdmin = 'SysAdmin', //Auction System Administrator
    AgencyAdmin = 'AgencyAdmin', //Auction Agency Administrator
    Agent = 'Agent',
    Guest = 'Guest',
    MarketOperator = 'MarketOp',
    SaleyardOperator = 'SaleyardOp',
    AuctionAdmin = 'AuctionAdmin', //Auction Administrator
    Buyer = 'Buyer',
}
