import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { AppConfigurationService } from './app-configuration.service'

@Injectable()
export class HeaderService {
    private headerTitleSubject = new BehaviorSubject<string>('')
    private headerSubtitleSubject = new BehaviorSubject<string>('')

    headerTitle$ = this.headerTitleSubject.asObservable()
    headerSubtitle$ = this.headerSubtitleSubject.asObservable()

    constructor(private router: Router, private appConfigService: AppConfigurationService) {}

    setHeaderTitle(title: string) {
        this.headerTitleSubject.next(title)
    }

    clearHeaderTitle() {
        this.headerTitleSubject.next('')
    }

    setHeaderSubtitle(subtitle: string) {
        this.headerSubtitleSubject.next(subtitle)
    }

    clearHeaderSubtitle() {
        this.headerSubtitleSubject.next('')
    }

    goBack() {
        let redirectUrl: string | null = '/'
        if (this.getRedirectUrl()) {
            redirectUrl = this.getRedirectUrl()
        } else if (this.router.url.indexOf('manage-saleyard-auction') !== -1) {
            redirectUrl = this.appConfigService.getConfig().manageWebsiteUrl
        } else if (this.router.url.indexOf('manage-auction') !== -1) {
            redirectUrl = this.appConfigService.getConfig().manageWebsiteUrl
        } else if (this.router.url.indexOf('auctions') !== -1) {
            redirectUrl = this.appConfigService.getConfig().publicWebsiteUrl
        }
        window.location = <any>decodeURIComponent(redirectUrl!)
    }

    getRedirectUrl() {
        let redirectSeparator = '?redirect='
        if (this.router.url.indexOf(redirectSeparator) !== -1) {
            return this.router.url.substring(this.router.url.indexOf(redirectSeparator) + redirectSeparator.length)
        }
        return null
    }

    goBackText() {
        return 'Go Back'
    }
}
