import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { OidcSecurityService } from 'angular-auth-oidc-client'
import { UserService } from 'app/core/services/user.service'
import { take } from 'rxjs/operators'

@Component({
    templateUrl: './auto-logout.component.html',
    styleUrls: ['./auto-logout.component.css'],
})
export class AutoLogoutComponent implements OnInit {
    constructor(private router: Router, private oidcSecurityService: OidcSecurityService, private userService: UserService) {}

    ngOnInit() {
        setTimeout(() => {
            this.oidcSecurityService.isAuthenticated$.pipe(take(1)).subscribe(isAuthorized => {
                if (isAuthorized) {
                    // If not yet logged off, log user of
                    this.userService.logOut()
                } else {
                    // If already logged off, then just redirect to post-logout
                    this.router.navigate(['post-logout'])
                }
            })
        }, 1500)
    }
}
