import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs'
@Injectable()
export class LocalStorageService {
    storageChanges$: Observable<StorageEvent>;

    constructor() {
        this.storageChanges$ = fromEvent<StorageEvent>(window, 'storage');
    }

    getStorageChanges(): Observable<StorageEvent> {
        return this.storageChanges$;
    }
}
