import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { LayoutComponent } from './_layout/layout/layout.component'
import { BtLayoutComponent } from './_layout/bt-layout/bt-layout.component'

import { ErrorComponent } from './core/error/error.component'
import { AutoLogoutComponent } from './core/auto-logout/auto-logout.component'
import { EmptyComponent } from './core/empty/empty.component'
import { AutoLoginComponent } from './core/auto-login/auto-login.component'
import { PostLogoutComponent } from './core/post-logout/post-logout.component'
import { HomeComponent } from 'app/home/home.component'

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'manage-saleyard-auction',
        loadChildren: () => import('app/modules/manage/saleyard/manage-saleyard-auction.module').then(m => m.ManageSaleyardAuctionModule),
    },
    {
        path: '',
        component: BtLayoutComponent,
        children: [
            {
                path: 'error',
                component: ErrorComponent,
            },
            {
                path: 'unauthorized',
                component: ErrorComponent,
                data: {
                    message: 'You are not authorized to access this page.',
                    errorCode: '401',
                },
            },
            {
                path: 'forbidden',
                component: ErrorComponent,
                data: {
                    message: 'You do not have the necessary permissions to access this page.',
                    errorCode: '403',
                },
            },
            {
                path: 'not-found',
                component: ErrorComponent,
                data: { message: 'Page does not exist.', errorCode: '404' },
            },
            {
                path: 'auto-logout',
                component: AutoLogoutComponent,
            },
            {
                path: 'auto-login',
                component: AutoLoginComponent,
            },
            {
                path: 'post-logout',
                component: PostLogoutComponent,
            },
            {
                path: 'manage-auction',
                loadChildren: () => import('app/modules/manage/sequential/manage-auction.module').then(m => m.ManageAuctionModule),
            },
            {
                path: 'monitoring',
                loadChildren: () => import('app/monitoring/monitoring.module').then(m => m.MonitoringModule),
            },
        ],
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'auctions',
                loadChildren: () => import('app/modules/public/auction.module').then(m => m.AuctionModule),
            },
        ],
    },
    { path: '', component: EmptyComponent, pathMatch: 'full' },
    { path: '**', redirectTo: 'not-found' },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
