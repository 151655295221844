import { PipeTransform, Pipe } from '@angular/core'

@Pipe({
    name: 'absoluteUrl',
})
export class AbsoluteUrlPipe implements PipeTransform {
    transform(url: string) {
        if (!url) {
            return url
        }

        // check if url is already prefixed with http or https
        if (!url.toLowerCase().startsWith('https://') && !url.toLowerCase().startsWith('http://')) {
            url = 'http://' + url
        }
        return url
    }
}
