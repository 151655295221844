<div>
    <div class="no-auction-screen">
        <mat-icon svgIcon="cow-goes-moo" class="open-icon"></mat-icon>
        <div class="auction-title">Auction Unavailable</div>
        <div class="sub-title">Sorry, we don't support this browser.</div>
        <div class="closing-message">
            To ensure the best experience for our customers, we recommend using<br />
            <a href="https://www.google.com/chrome/"> Google Chrome </a> or
            <a href="https://www.mozilla.org/en-US/firefox/new/"> Mozilla Firefox. </a>
        </div>
        <div class="closing-message">If you need further assistance, please call the bidr® team on 0800 TO BIDR (0800 86 2437)</div>
    </div>
</div>
