import { Component, OnInit, Input } from '@angular/core'

@Component({
    selector: 'app-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit {
    @Input() text: string
    @Input() color?: string
    @Input() size?: 'sm' | 'md'

    constructor() {}

    ngOnInit() {}
}
