import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { HubClientService } from '../../core/services/hub-client/hub-client.service'

@Injectable()
export class LatencyCalculationService {
    private windowSize = 10
    private frequency = 2000
    private measurements: number[] = []
    private runningInterval: any
    private latencySubject = new BehaviorSubject<number | null>(null)

    latency$ = this.latencySubject.asObservable()

    constructor(private hubClientService: HubClientService) {}

    startMeasurement() {
        this.runningInterval = setInterval(() => {
            const timeStart = performance.now()
            this.hubClientService.callHubMethod('Ping').subscribe(next => {
                const timeEnd = performance.now()
                const rtt = timeEnd - timeStart
                const halfRtt = rtt / 2 // Half the RTT for server to client trip time
                this.insertMeasurement(halfRtt)
            })
        }, this.frequency)
    }

    stopMeasurement() {
        clearTimeout(this.runningInterval)
    }

    /**
     * If latency is calculated, this method will return the latest calculation
     * */
    private getCurrentLatency(): number {
        const currentLatency = this.latencySubject.value
        return currentLatency != null ? (currentLatency >= 0 ? currentLatency : 0) : 0
    }

    private insertMeasurement(tripTime: number) {
        if (this.measurements.length === this.windowSize) {
            // Remove first (oldest) item in array to make room
            this.measurements.shift()
        }
        this.measurements.push(tripTime)
        if (this.measurements.length === this.windowSize) {
            const avgLatency = this.measurements.reduce((a, b) => a + b, 0) / this.measurements.length
            this.latencySubject.next(avgLatency)
            this.hubClientService.updateLatencyAdjustment(this.getCurrentLatency())
        }
    }
}
