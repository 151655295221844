import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs'
import { RoleTypes } from '../constants/role-types.enum'
import { UserProfile } from '../models/user-profile.model'
import { AppConfigurationService } from '../services/app-configuration.service'
import { HeaderService } from '../services/header.service'
import { UserService } from '../services/user.service'

@Component({
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css'],
})
export class ErrorComponent implements OnInit, OnDestroy {
    title: string
    message: string
    errorCode: string
    subscriptions: Subscription[] = []
    userProfile: UserProfile | null
    errorDateTime: Date = new Date()

    constructor(
        private activatedRoute: ActivatedRoute,
        private appConfigService: AppConfigurationService,
        private userService: UserService,
        private headerService: HeaderService | null
    ) {}

    ngOnInit() {
        this.errorDateTime = new Date()
        this.subscriptions.push(
            this.userService.currentUser$.subscribe(userProfile => {
                this.userProfile = userProfile
                this.subscriptions.push(
                    this.activatedRoute.data.subscribe(data => {
                        this.errorCode = data['errorCode']

                        if (this.userProfile != null) {
                            const roleKey = this.userProfile.role!.key
                            if (![RoleTypes.SaleyardOperator.toString(), RoleTypes.MarketOperator.toString()].includes(roleKey)) {
                                const publicSiteUrl = this.appConfigService.getConfig().publicWebsiteUrl
                                switch (this.errorCode) {
                                    case '401':
                                    case '403':
                                        window.location = <any>`${publicSiteUrl}/forbidden`
                                        break
                                    case '404':
                                    case '500':
                                    default:
                                        window.location = <any>`${publicSiteUrl}/error`
                                        break
                                }
                            }
                        }

                        if (this.errorCode === '401' || this.errorCode === '403') {
                            this.title = 'No access!'
                            this.message = "You currently don't have permission to view this page"
                        } else {
                            this.title = 'An error occurred!'
                            this.message = "Either the page doesn't exist or something has gone wrong"
                        }
                    })
                )
            })
        )
    }

    onContinueClicked() {
        this.headerService!.goBack()
    }

    getButtonText() {
        return this.headerService!.goBackText()
    }

    getReadableRedirectUrl() {
        return this.headerService!.getRedirectUrl() ? decodeURIComponent(this.headerService!.getRedirectUrl()!) : null
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe())
    }
}
