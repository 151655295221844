import 'hammerjs'

import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

import * as Sentry from '@sentry/angular-ivy'
import { BehaviorSubject } from 'rxjs'
import { version } from './assets/version'

export const baseConfig = new BehaviorSubject<any>('')

if (environment.production) {
    enableProdMode()
}

fetch('/assets/configuration.json?v=1')
    .then(res => res.json())
    .then(config => {
        baseConfig.next(config)

        if (config.environment !== 'Local' || (config.environment === 'Local' && config.enableSentryOnDev)) {
            Sentry.init({
                dsn: config.sentry_dsn,
                environment: config.environment,
                tracesSampleRate: config.sentry_tracesSampleRate,
                replaysSessionSampleRate: config.sentry_replaysSessionSampleRate,
                replaysOnErrorSampleRate: config.sentry_replaysOnErrorSampleRate,
                release: version,
            })
        }

        platformBrowserDynamic()
            .bootstrapModule(AppModule)
            .catch(err => console.error(err))
    })
