import { Component, OnInit, HostBinding, HostListener } from '@angular/core'
import { Observable } from 'rxjs'

import { LatencyCalculationService } from '../services/latency-calculation.service'

@Component({
    selector: 'app-latency-display',
    templateUrl: './latency-display.component.html',
    styleUrls: ['./latency-display.component.css'],
})
export class LatencyDisplayComponent implements OnInit {
    @HostBinding('style.opacity') opacity = 0
    pinned = false
    latency$: Observable<number | null>

    constructor(private latencyService: LatencyCalculationService) {}

    @HostListener('mouseover', ['$event.target'])
    onMouseOver(src) {
        if (!this.pinned) {
            this.opacity = 1
        }
    }

    @HostListener('mouseout', ['$event.target'])
    onMouseOut(src) {
        if (!this.pinned) {
            this.opacity = 0
        }
    }

    @HostListener('click', ['$event.target'])
    onMouseClick(src) {
        this.pin()
    }

    ngOnInit() {
        this.latency$ = this.latencyService.latency$
    }

    pin() {
        this.pinned = !this.pinned
        this.opacity = this.pinned ? 1 : 0
    }
}
