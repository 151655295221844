import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'basicFilter',
})
export class BasicFilterPipe implements PipeTransform {
    transform(array: any[] | null, propertyName: string | null, filterValue: any): any {
        if (array == null) {
            return array
        }
        if (propertyName == null) {
            return array
        }
        if (array.length < 1) {
            return array
        }
        return array.filter(i => i[propertyName] === filterValue)
    }
}
