import { Injectable } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class AppSpinnerService {
    private ngxLoadingSpinnerTextSubject = new BehaviorSubject<string>('')
    ngxLoadingSpinnerText$ = this.ngxLoadingSpinnerTextSubject.asObservable()

    constructor(private ngxLoadingSpinner: NgxSpinnerService) {}

    show(message?: string) {
        this.ngxLoadingSpinnerTextSubject.next(message || '')
        this.ngxLoadingSpinner.show()
    }

    hide() {
        this.ngxLoadingSpinnerTextSubject.next('')
        this.ngxLoadingSpinner.hide()
    }
}
