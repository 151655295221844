import { LayoutModule } from '@angular/cdk/layout'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ModalModule } from 'ngx-bootstrap/modal'
import { TabsModule } from 'ngx-bootstrap/tabs'
import { ButtonsModule } from 'ngx-bootstrap/buttons'
import { CarouselModule } from 'ngx-owl-carousel-o'
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering'
import { VgControlsModule } from '@videogular/ngx-videogular/controls'
import { VgCoreModule } from '@videogular/ngx-videogular/core'
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play'
import { MaterialModule } from '../core/material.module'
import { ExternalLinkComponent } from './components/external-link/external-link.component'
import { LabelComponent } from './components/label/label.component'
import { MediaGalleryComponent } from './components/media-gallery/media-gallery.component'
import { ContainerDirective } from './container.directive'
import { CurrencyMaskDirective } from './currency-mask/currency-mask.directive'
import { GoHomeDirective } from './go-home.directive'
import { ArrayPadPipe } from './pipes/array-pad.pipe'
import { AbsoluteUrlPipe } from './pipes/absolute-url.pipe'
import { BasicFilterPipe } from './pipes/basic-filter.pipe'
import { MinuteSecondsPipe } from './pipes/minute-seconds.pipe'
import { TruncatePipe } from './pipes/truncate.pipe'
import { ToggleButtonComponent } from './toggle-button/toggle-button.component'
import { NoAuctionComponent } from './components/no-auction/no-auction.component'

// Avoid declaring providers in SharedModule, if providers need to be shared
// globally, put it in the core services.
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ButtonsModule.forRoot(),
        LayoutModule,
        DragDropModule,
        CarouselModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        MaterialModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToggleButtonComponent,
        TabsModule,
        ArrayPadPipe,
        AbsoluteUrlPipe,
        BasicFilterPipe,
        TruncatePipe,
        MinuteSecondsPipe,
        CurrencyMaskDirective,
        ContainerDirective,
        ModalModule,
        ExternalLinkComponent,
        MediaGalleryComponent,
        LabelComponent,
        GoHomeDirective,
        MaterialModule,
        NoAuctionComponent,
        CarouselModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
    ],
    declarations: [
        ToggleButtonComponent,
        ArrayPadPipe,
        AbsoluteUrlPipe,
        BasicFilterPipe,
        TruncatePipe,
        MinuteSecondsPipe,
        CurrencyMaskDirective,
        ContainerDirective,
        ExternalLinkComponent,
        MediaGalleryComponent,
        GoHomeDirective,
        LabelComponent,
        NoAuctionComponent,
    ],
})
export class SharedModule {}
