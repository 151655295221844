import { Injectable } from '@angular/core'
import { OidcSecurityService } from 'angular-auth-oidc-client'
import { mergeMap, take, tap, map, finalize } from 'rxjs/operators'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { NgxSpinnerService } from 'ngx-spinner'

import { UserProfile } from '../models/user-profile.model'
import { ApiService } from '../../modules/manage/sequential/services/api.service'
import { userData } from 'app/core/services/app-loader.service'

@Injectable()
export class UserService {
    private currentUserSubject = new BehaviorSubject<UserProfile | null>(null)
    currentUser$ = this.currentUserSubject.asObservable()

    constructor(private api: ApiService, private oidcSecurityService: OidcSecurityService, private spinner: NgxSpinnerService) {}

    /**
     * Loads the users information and permissions from the API
     * If already loaded, will return the user profile stored in memory
     * */
    loadUserProfile(): Observable<UserProfile> {
        const userDataLoaded = userData.value
        if (this.currentUserSubject.value == null) {
            const getUserData$ = this.oidcSecurityService.userData$.pipe(
                take(1),
                tap(() => this.spinner.show(userDataLoaded?.userData)),
                mergeMap(() => this.api.getUserProfile(userDataLoaded?.userData?.sub)),
                map(userProfileResponse => {
                    const userProfile: UserProfile = { ...userProfileResponse }
                    return userProfile
                }),
                tap(userProfile => {
                    this.currentUserSubject.next(userProfile)
                }),
                finalize(() => this.spinner.hide())
            )
            return this.oidcSecurityService.getUserData().pipe(mergeMap(gotUserInfo => getUserData$))
        } else {
            const userProfile = this.currentUserSubject.value
            return of(userProfile)
        }
    }

    loadUserInfo(loginResponse): Observable<UserProfile> {
        userData.next(loginResponse)

        const getUserData$ = this.oidcSecurityService.userData$.pipe(
            take(1),
            mergeMap(() => this.api.getUserProfile(loginResponse.userData.sub)),
            map(userProfileResponse => {
                const userProfile: UserProfile = { ...userProfileResponse }
                return userProfile
            }),
            tap(userProfile => {
                this.currentUserSubject.next(userProfile)
            }),
            finalize(() => this.spinner.hide())
        )

        const cookie = this.getCookieData()
        document.cookie = `${cookie.name}=yes;path=/;domain=${cookie.domain};`

        const cookieLogin = this.getCookieData('IsNewLogin')
        const cookieLoginValue = this.getCookie(cookieLogin.name)

        if (cookieLoginValue === 'yes') {
            document.cookie = `${cookieLogin.name}=;path=/;domain=${cookie.domain};expires=Thu, 01 Jan 1970 00:00:01 GMT;`
            localStorage.setItem('auctionsMuted', '[]')
        }

        return this.oidcSecurityService.getUserData().pipe(mergeMap(gotUserInfo => getUserData$))
    }

    logOut() {
        this.currentUserSubject.next(null)
        this.oidcSecurityService.logoff().subscribe()

        const cookie = this.getCookieData()
        document.cookie = `${cookie.name}=;path=/;domain=${cookie.domain};expires=Thu, 01 Jan 1970 00:00:01 GMT;`

        localStorage.setItem('auctionsMuted', '[]')
    }

    getCookie(name: string): string {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
        if (match) return match[2]
        return ''
    }

    getCookieData(name: string = 'isAuthenticated') {
        const cookie = {
            name: name,
            domain: this.getCookieDomain(),
        }

        if (location.host.includes('localhost')) {
            cookie.name += '_dev'
            cookie.domain = 'localhost'
        }

        if (location.host.includes('.qa.')) {
            cookie.name += '_qa'
        }

        if (location.host.includes('.uat.')) {
            cookie.name += '_uat'
        }

        return cookie
    }

    getCookieDomain() {
        let host = location.host.replace('wwww.', '')
        let domain = host.split('.')
        let out: any = []

        const dotArray: Array<string> = []
        dotArray.push('')

        if (domain.length > 3) {
            domain = domain.splice(1, domain.length)
            out = dotArray.concat(domain)
            return out.join('.')
        }

        if (!host.includes('localhost')) {
            out = dotArray.concat(domain)
            return out.join('.')
        }

        return domain.join('.')
    }
}
