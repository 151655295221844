import { Directive, forwardRef, Input, ElementRef, AfterViewInit, HostListener } from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'

const noop = () => {}

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CurrencyMaskDirective),
    multi: true,
}

@Directive({
    selector: '[appCurrencyMask]',
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CurrencyMaskDirective implements AfterViewInit, ControlValueAccessor {
    private prefix: string
    private decimalSeparator: string
    private thousandsSeparator: string
    private el: any
    // Keeps track of the value without formatting
    private innerValue: any

    // Optional parameter to allow for negative number interaction
    @Input() allowNegative: boolean
    @Input() restrictTwoDp: boolean = false

    // @ts-ignore: 'elementRef value is never read but is being used on line 29'
    constructor(private elementRef: ElementRef) {
        this.el = elementRef.nativeElement
        this.prefix = '$'
        this.decimalSeparator = '.'
        this.thousandsSeparator = ','
    }

    private onTouchedCallback: () => void = noop
    private onChangeCallback: (a: any) => void = noop

    @Input()
    // set getter
    get value(): any {
        return this.innerValue
    }

    // set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.el.value = this.transform(v, this.allowNegative)
            this.innerValue = v
            this.onChangeCallback(v)
        }
    }

    ngAfterViewInit(): void {
        this.el.style.textAlign = 'left'
    }

    writeValue(value: any): void {
        this.el.value = this.transform(value, this.allowNegative)
        this.innerValue = value
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }

    // On Focus remove all non-digit or decimal separator values
    @HostListener('focus', ['$event.target.value'])
    onfocus(value) {
        this.onTouchedCallback()
        this.el.value = this.parse(value, this.allowNegative)
    }

    // On Blue remove all symbols except last . and set to currency format
    @HostListener('blur', ['$event.target.value'])
    onBlur(value) {
        this.onTouchedCallback()
        this.el.value = this.transform(value, this.allowNegative)
    }

    // On Change remove all symbols except last . and set to currency format
    @HostListener('change', ['$event.target.value'])
    onChange(value) {
        this.el.value = this.transform(value, this.allowNegative)
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value) {
        this.onChangeCallback(this.parse(this.el.value, this.allowNegative))
    }

    // Prevent user to enter anything but digits and decimal separator
    @HostListener('keypress', ['$event'])
    onKeyPress(event) {
        const key = event.which || event.keyCode || 0
        if (key === 45 && !this.allowNegative) {
            event.preventDefault()
        } else if (key === 45 && this.allowNegative) {
            // allow negative numbers
        } else if (key !== 46 && key > 31 && (key < 48 || key > 57)) {
            event.preventDefault()
        }

        if (this.restrictTwoDp) {
            const decimalPlaces = 2
            if (event instanceof KeyboardEvent) {
                const cursorPosition = (<any>event.target).selectionStart
                const value = (<any>event.target).value
                const decimalPointIndex = value.indexOf('.')
                const ch = String.fromCharCode(event.which)
                if (ch === '.' && value.indexOf('.') !== -1) {
                    event.preventDefault()
                }
                if (ch === '.' && value.indexOf('.') === -1) {
                    if (cursorPosition < value.length - decimalPlaces) {
                        event.preventDefault()
                    }
                }
                if (decimalPointIndex !== -1) {
                    const maxLength = decimalPointIndex + decimalPlaces + 1
                    if (cursorPosition > decimalPointIndex && value.length >= maxLength) {
                        event.preventDefault()
                    }
                }
            }
        }
    }

    transform(value: string, allowNegative = false, decimalPrecision: number = 2) {
        if (value == undefined || value === '') {
            return null
        }
        if (allowNegative) {
            value = value.toString()
            if (value.startsWith('(') || value.startsWith('-')) {
                value = '-' + value.substr(1, value.length).replace(/\(|\)|\$|\-/g, '')
            } else {
                value = value.replace(/\(|\)|\$|\-/g, '')
            }
        }
        let [integer, fraction = ''] = (value || '').toString().split(this.decimalSeparator)
        fraction = decimalPrecision > 0 ? this.decimalSeparator + (fraction + '000000').substring(0, 2) : ''
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparator)
        // If user types .xx we can display 0.xx
        if (integer === '') {
            integer = '0'
        } else if (integer.startsWith('$')) {
            // If there are multiple transforms, remove the previous dollar sign (blur and change at the same time)
            integer = integer.substr(1, integer.length)
        } else if (allowNegative && integer.startsWith('-')) {
            // If user inputs negative number set to paranthesis format
            integer = integer.substr(1, integer.length)
            return '(' + this.prefix + integer + fraction + ')'
        }
        return this.prefix + integer + fraction
    }

    parse(value: string, allowNegative = false) {
        let [integer, fraction = ''] = (value || '').split(this.decimalSeparator)
        integer = integer.replace(new RegExp(/[^\d\.]/, 'g'), '')
        fraction = parseInt(fraction, 10) > 0 && 2 > 0 ? this.decimalSeparator + (fraction + '000000').substring(0, 2) : ''
        if (allowNegative && value.startsWith('(') && value.endsWith(')')) {
            return (-1 * parseFloat(integer + fraction)).toString()
        } else {
            return integer + fraction
        }
    }
}
