import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'minuteSeconds',
})
export class MinuteSecondsPipe implements PipeTransform {
    transform(value: number | null): any {
        if (value == null) {
            return '00:00'
        }
        if (value < 0) {
            return '00:00'
        }
        const secondsValue = value / 1000 // since value timeRemaining is now in ms
        const minutes: number = Math.floor(secondsValue / 60)
        const seconds = Math.ceil(secondsValue - minutes * 60)
        const minutesString = this.padStart(minutes.toString(), 2, '0')
        const secondsString = this.padStart(seconds.toString(), 2, '0')
        return `${minutesString}:${secondsString}`
    }

    private padStart(stringToPad: string, targetLength: number, padString: string) {
        targetLength = targetLength >> 0 //truncate if number or convert non-number to 0;
        padString = String(typeof padString !== 'undefined' ? padString : ' ')
        if (stringToPad.length > targetLength) {
            return String(stringToPad)
        } else {
            targetLength = targetLength - stringToPad.length
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length) //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(stringToPad)
        }
    }
}
