import { NgModule } from '@angular/core'
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { TabsModule } from 'ngx-bootstrap/tabs'
import { ModalModule } from 'ngx-bootstrap/modal'
import { NgxSpinnerModule } from 'ngx-spinner'
import { ToastrModule } from 'ngx-toastr'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'

import { UserService } from './services/user.service'
import { SharedModule } from '../shared/shared.module'
import { UrlService } from '../modules/manage/sequential/services/url.service'
import { ApiService } from '../modules/manage/sequential/services/api.service'
import { SaleyardApiService } from '../modules/manage/saleyard/services/saleyard-api.service'
import { LotMediaApiService } from '../modules/manage/saleyard/services/lot-media-api.service'
import { AuctionMediaApiService } from '../modules/manage/saleyard/services/auction-media-api.service'
import { ErrorComponent } from './error/error.component'
import { HeaderComponent } from './header/header.component'
import { HeaderService } from './services/header.service'
import { AuthInterceptor } from './interceptors/auth-interceptor.service'
import { PermissionsGuard } from './guards/permissions-guard.service'
import { AutoLoginGuard } from './guards/auto-login-guard.service'
import { AppConfigurationService } from './services/app-configuration.service'
import { HubClientService } from './services/hub-client/hub-client.service'
import { AutoLogoutComponent } from './auto-logout/auto-logout.component'
import { AppSpinnerService } from './services/app-spinner.service'
import { AppSpinnerWrapperComponent } from './app-spinner-wrapper/app-spinner-wrapper.component'
import { EmptyComponent } from './empty/empty.component'
import { AutoLoginComponent } from './auto-login/auto-login.component'
import { RoleGuard } from './guards/role-guard.service'
import { SoundService } from './services/sound.service'
import { SessionTimeoutService } from './services/session-timeout.service'
import { PostLogoutComponent } from './post-logout/post-logout.component'
import { LogoComponent } from './logo/logo.component'
import { BtHeaderComponent } from './bt-header/bt-header.component'
import { MaterialModule } from './material.module'
import { LocalStorageService } from 'app/core/services/local-storage.service'
import { LayoutService } from 'app/core/services/layout.service'

// This module should only be imported on the AppModule and nowhere else
// Declare providers here that need to be used/shared globally across the app
@NgModule({
    declarations: [
        ErrorComponent,
        HeaderComponent,
        AutoLogoutComponent,
        AppSpinnerWrapperComponent,
        EmptyComponent,
        AutoLoginComponent,
        PostLogoutComponent,
        LogoComponent,
        BtHeaderComponent,
    ],
    exports: [HttpClientModule, HeaderComponent, NgxSpinnerModule, AppSpinnerWrapperComponent, BtHeaderComponent, MaterialModule],
    imports: [
        SharedModule,
        TabsModule.forRoot(),
        NgxSpinnerModule.forRoot(),
        ToastrModule.forRoot({
            preventDuplicates: true,
            positionClass: 'toast-top-full-width',
        }),
        ModalModule.forRoot(),
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MaterialModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        UserService,
        UrlService,
        ApiService,
        SaleyardApiService,
        LotMediaApiService,
        AuctionMediaApiService,
        AutoLoginGuard,
        PermissionsGuard,
        HeaderService,
        AppConfigurationService,
        HubClientService,
        AppSpinnerService,
        RoleGuard,
        SoundService,
        SessionTimeoutService,
        LocalStorageService,
        LayoutService,
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class CoreModule {}
