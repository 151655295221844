import { Component, Input, OnInit } from '@angular/core'
import { AppSpinnerService } from '../services/app-spinner.service'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-app-spinner-wrapper',
    template: `<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" [disableAnimation]="true" type="ball-spin-clockwise" [fullScreen]="false"
        ><p style="color: #fff;">{{ appSpinnerText$ | async }}</p></ngx-spinner
    > `,
    styles: [],
})
export class AppSpinnerWrapperComponent implements OnInit {
    @Input() loadingText: Observable<string>
    @Input() threshold: number

    appSpinnerText$: Observable<string>

    constructor(private appSpinnerService: AppSpinnerService) {}

    ngOnInit() {
        this.appSpinnerText$ = this.appSpinnerService.ngxLoadingSpinnerText$
    }
}
